/*------------------------------------*\
    #CONTACT
\*------------------------------------*/

/* Form */
.contact-form {
    background-color: #fff;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 2rem;

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: 2.2rem;
        margin-bottom: 2em;
    }

    .form-field {
        @extend %clearfix;
        max-width: 57rem;
        margin: 1em auto;

        > label:not(.sr-only) {
            font-size: 1.4rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        label {
            input[type="text"] {
                width: auto;
            }
        }
    }

    .form-action {
        margin-top: 2em;
    }

    [type="text"],
    [type="email"],
    [type="tel"],
    select,
    textarea {
        width: 100%;
    }

    [type="checkbox"] {
        + label {
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    hr  {
        max-width: 57rem;
        margin: 1em auto;
    }

    input:-moz-placeholder,
    input::-moz-placeholder,
    textarea:-moz-placeholder,
    textarea::-moz-placeholder {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }

    ::-webkit-input-placeholder {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }


    @include mq($from: tablet){
        padding: 4em;
    }
}

.contact-navigation {
    @extend .row;

    width: 100%;
    max-width: 100rem;
    margin: 0 auto 2rem;

    > a {
        padding: 2rem;
        background-color: #ffffff;
        color: $c-darkergray;
        text-decoration: none;
        transition: all .3s ease-in-out;
        display: block;

        div {
            margin-left: 2rem;

            &:first-of-type {
                font-weight: bold;
                font-size: 1em;
                text-transform: uppercase;
            }

            &:last-of-type {
                font-size: .9em;
            }
        }


        &.active {
            box-shadow: 0 20px 20px -20px rgba(147, 195, 233, .7);
        }

        &:not(.active) {
            background-color: rgba(#ffffff, .6);
            //color: lighten(#1e1e1e, 30%);
            color: #999999;
        }

        &:hover {
            &:not(.active) {
                background-color: rgba(#ffffff, 1);
            }
        }
    }

    &__column_2 {
        > a {
            @extend .col-sm-6;
            @extend .col-xs-12;
        }
    }

    &__column_3 {
        > a {
            @extend .col-sm-4;
            @extend .col-xs-12;
        }
    }
}

.form-field__date {
    input[type=text] {
        width: 6rem !important;

        &[name=year] {
            width: 8rem !important;
        }
    }
}

/* Styles pour le bouton WhatsApp flottant */
.whatsapp-container {
    position: fixed;
    bottom: 30px;
    right: 0px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.whatsapp-bubble {
    background-color: rgba(147, 195, 233, 0.9);
    color: #333;
    border-radius: 35px 0 0 35px;
    padding: 0px 15px 0px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 110px;
    position: relative;
    opacity: 0;
    transform: translateY(0);
    animation: fadeInBubble 0.5s ease forwards;
    animation-delay: 0.5s;
    margin-left: -15px;
    padding-left: 27px;
    z-index: 1;
    width: auto;
    min-height: 80px;
    height: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}


.whatsapp-bubble p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    position: relative;
    z-index: 1;
    white-space: normal;
    hyphens: none;
    word-break: keep-all;
    max-width: 100%;
    padding: 8px 0;
}

@keyframes fadeInBubble {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.whatsapp-container a {
    text-decoration: none;
}

.whatsapp-float {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    position: relative;
    z-index: 2;
}

.whatsapp-float {
    text-decoration: none;
    background-color: #25D366;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.whatsapp-float .fab.fa-whatsapp {
    color: white;
    font-size: 72px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.whatsapp-container:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;

}

/* Ajustements responsifs */
@media (max-width: 767px) {
    .whatsapp-container {
        bottom: 70px;
    }
    
    .whatsapp-float i {
        width: 55px;
        height: 55px;
    }
    
    .whatsapp-float .fab.fa-whatsapp {
        font-size: 30px;
    }
}
